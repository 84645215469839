
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { RoomDto, LiveStreamType } from '../../services';
import { TranslateResult } from '../../types/i18n';
import LiveAppRoomRedirect from './LiveAppRoomRedirect.vue';

@Component({ components: { LiveAppRoomRedirect } })
export default class RoomCta extends Vue {
  @Prop({ required: true }) room!: RoomDto;
  @Prop({ required: true }) sessionId!: string;
  @Prop({ required: false }) buttonClass!: string;
  @Prop(Boolean) hasPresentations!: boolean;
  @Prop(Boolean) readonly isLiveApp!: boolean;
  @Prop(String) ctaLabelOverride?: string;
  @Prop({ default: 'primary' }) hierarchy?: string;
  @Prop({ default: 'md' }) size?: string;

  readonly LiveStreamType = LiveStreamType;

  get hasIcon() {
    return this.room.liveStreamType !== LiveStreamType.VirtualPosterSession;
  }

  get query() {
    return this.room.liveStreamType === LiveStreamType.VirtualPosterSession ? { sessionIds: this.sessionId } : {};
  }

  get roomRouteName() {
    if (this.room.liveStreamType === LiveStreamType.VirtualPosterSession) return 'slug-posters';
    return undefined;
  }

  get ctaLabel(): TranslateResult {
    if (this.ctaLabelOverride) return this.ctaLabelOverride;
    return this.room?.liveStreamType === LiveStreamType.VirtualPosterSession
      ? this.$t('shared.sessions.cta_poster')
      : this.$t('shared.sessions.cta_livestream');
  }
}
