import { AddOnType } from './AddOnDto';
import { BillingInfoDto } from './BillingInfoDto';
import { PackageTier } from './PackageDto';
import { OrganizationCurrency } from './OrganizationDto';

interface UpgradePackageDto {
  id: string;
  cost: number;
  fee: number;
}

interface UpgradeAddOnDto {
  id: string;
  cost: number;
}

export interface UpgradePricingDto {
  eventId: string;
  package: UpgradePackageDto;
  addOns: UpgradeAddOnDto[];
  billingInfo?: BillingInfoDto | null;
}

export enum UpgradeItemType {
  CurrentPlanCredit = 'CurrentPlanCredit',
  MultiYearDiscount = 'MultiYearDiscount',
  MultiEventDiscount = 'MultiEventDiscount',
  IncludedInPackage = 'IncludedInPackage',
  Charge = 'Charge',
}

export interface UpgradeItemDto {
  addOnType: AddOnType | null;
  packageTier: PackageTier | null;
  cost: number;
  gst: number;
  qst: number;
  hst: number;
  total: number;
  unitCost: number;
  type: UpgradeItemType;
}

export interface UpgradePricingResponseDto {
  items: UpgradeItemDto[];
  subtotal: number;
  gst: number;
  qst: number;
  hst: number;
  total: number;
  currency: OrganizationCurrency;
  termLengthInYears: number;
  maxActiveEvents: number;
  planStartDate: string;
  planEndDate: string;
  nextInvoiceDate: string | null;
  nextInvoiceTotal: number;
}
