import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { InputFileListEntry, InputFileMeta } from '../types';

/**
 * This mixin provides the props of the component `InputFileEntry`
 * slot by the name `entry`.
 */
@Component
export class InputFileEntryMixin extends Vue {
  @Prop({ type: Object, required: true }) readonly fileEntry!: InputFileListEntry;
  @Prop({ type: Object, required: true }) readonly fileMeta!: InputFileMeta;

  @Prop({ type: Function, default: () => {} }) readonly deleteHandler!: () => void;
  @Prop({ type: Function, default: () => {} }) readonly imgErrorHandler!: () => void;
  @Prop({ type: Function, default: () => {} }) readonly imgLoadHandler!: () => void;
  @Prop({ type: Boolean, default: false }) readonly imgPreviewVisible!: boolean;
  @Prop({ type: Boolean, default: false }) readonly isDisabled!: boolean;
  @Prop({ type: Boolean, default: false }) readonly isDraggable!: boolean;
  @Prop({ type: Boolean, default: false }) readonly isReadonly!: boolean;
  @Prop({ type: Boolean, default: false }) readonly uploadHasComplete!: boolean;
  @Prop({ type: Boolean, default: false }) readonly uploadHasError!: boolean;
  @Prop({ type: Boolean, default: false }) readonly uploadProgressVisible!: boolean;

  @Prop({
    type: Object,
    default: Object.create({
      // The `handle` property is required for the draggable feature to work.
      handle: 'file-entry-handle',
    }),
  })
  readonly testIds!: Record<string, string>;

  @Prop({ type: String }) readonly deleteAriaLabel?: TranslateResult;
  @Prop({ type: Object }) readonly fileNameTruncated?: { start?: string; end?: string };
  @Prop({ type: String }) readonly imgPreviewUrl?: string | null;
  @Prop({ type: String }) readonly uploadErrorMessage?: TranslateResult;

  @Prop({
    validator: (val: string) => {
      return ['page', 'image', 'film'].includes(val);
    },
  })
  readonly fileIcon?: 'page' | 'image' | 'film';

  @Prop({
    validator: (val: number | null) => {
      return typeof val === 'number' || val === null;
    },
  })
  readonly uploadProgress?: number | null;
}
