
import { Component, Emit, Prop, Watch, mixins } from 'nuxt-property-decorator';
import { uniq, uniqWith, isEqual, omit } from 'lodash-es';
import { v4 as uuid } from 'uuid';
import Draggable from 'vuedraggable';
import type { UploadFileResult, UploadFileType } from '../../../../services';
import type { InputFileValue, InputFilePartialValue, InputFileListEntry, FileProcessor } from '../../../../types';
import { InputFileInvalidMessage } from '../../../../types';
import { isNumber, isString } from '../../../../helpers';
import InputValidationMixin from '../InputValidationMixin';
import InputFileEntry from './InputFileEntry.vue';
import { HeightTransition } from '../../../layout';

interface FileInputEvent extends InputEvent {
  target: HTMLInputElement & { files: FileList };
}

@Component({
  components: {
    HeightTransition,
    InputFileEntry,
    Draggable,
  },
})
export default class InputFile extends mixins(InputValidationMixin) {
  @Prop({ type: String, required: true }) readonly name!: string;
  @Prop({ type: String, required: true }) readonly uploadIdentifier!: string;
  @Prop({ type: String, required: true }) readonly uploadType!: UploadFileType;
  @Prop({ type: Array, default: Array }) readonly accept!: string[];
  @Prop({ type: Array, default: null }) readonly maxDimensions!: number[] | null;
  @Prop({ type: Array, default: null }) readonly recommendedDimensions!: number[] | null;
  @Prop({ type: Number, default: null }) readonly maxSize!: number | null;
  @Prop({ type: Number, default: null }) readonly maxKb!: number | null;
  @Prop({ type: Number, default: null }) readonly maxMb!: number | null;
  @Prop({ type: String, default: null }) readonly label!: string | null;
  @Prop({ type: Number, default: 1 }) readonly column!: number;
  @Prop({ type: Function, default: null }) readonly fileProcessor!: FileProcessor | null;
  @Prop(Boolean) readonly hideMaxSize!: boolean;
  @Prop(Boolean) readonly draggable!: boolean;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly readonly!: boolean;
  @Prop(Boolean) readonly multiple!: boolean;
  @Prop(Boolean) readonly image!: boolean;
  @Prop(Boolean) readonly video!: boolean;
  @Prop(Boolean) readonly pdf!: boolean;
  @Prop({ type: [Object, Array], default: null }) readonly value!:
    | InputFilePartialValue
    | InputFilePartialValue[]
    | null;

  fileList: InputFileListEntry[] = [];
  deletedEntries: string[] = [];
  invalidFiles: (InputFileListEntry & { invalidFile: InputFileInvalidMessage })[] = [];
  activeUploads: string[] = [];
  isFieldVisible = true;
  isDropping = false;
  spinnerId = uuid();

  get hasEntries() {
    return this.fileList.length > 0 || this.invalidFiles.length > 0;
  }

  get showErrors() {
    return this.required && this.fileList.length === 0 && this.hasValidationError;
  }

  get fieldClassNames() {
    return {
      '-error': this.hasValidationError,
      '-dropping': this.isDropping,
      '-disabled': this.disabled,
      '-readonly': this.readonly,
    };
  }

  get maxSizeBytes() {
    if (this.maxSize) return this.maxSize;
    if (this.maxKb) return this.maxKb * 1000;
    if (this.maxMb) return this.maxMb * 1e6;
    return null;
  }

  get acceptedFileTypes() {
    const fileTypeMap: [string[], boolean][] = [
      [['.jpeg', '.jpg', '.png'], this.image],
      [['application/pdf'], this.pdf],
      [['video/mp4'], this.video],
    ];

    const fileTypes = fileTypeMap.flatMap(([type, isAccepted]) => (isAccepted ? type : []));
    return uniq([...this.accept, ...fileTypes]);
  }

  get acceptedExtensionsText() {
    const extensions = this.acceptedFileTypes.map(type => type.replace(/(^\w+\/|^.)/, '').toUpperCase());
    if (!extensions.length) return this.$t('shared.inputs.file.all_file_types');

    const formattedExtensions =
      extensions.length === 1
        ? extensions.join('')
        : `${extensions.slice(0, -1).join(', ')} ${this.$t('shared.global.or')} ${extensions[extensions.length - 1]}`;

    return this.$t('shared.inputs.file.accepted_file_types', { fileTypes: formattedExtensions });
  }

  get fileLimitsText() {
    if (!this.maxDimensions && !this.maxSizeBytes && !this.recommendedDimensions) return '';

    const maxDimensions = this.maxDimensions ? `${this.maxDimensions.slice(0, 2).join('x')}px` : null;
    const recDimensions = this.recommendedDimensions ? `${this.recommendedDimensions.slice(0, 2).join('x')}px` : null;
    const maxSize = this.maxSizeBytes ? this.formatFileSize(this.maxSizeBytes) : null;

    const parts = [
      maxDimensions ? this.$t('shared.inputs.file.max_dimensions', { dimensions: maxDimensions }) : null,
      recDimensions ? this.$t('shared.inputs.file.recommended_dimensions', { dimensions: recDimensions }) : null,
      maxSize && !this.hideMaxSize ? this.$t('shared.inputs.file.max_size', { size: maxSize }) : null,
    ].filter(Boolean);

    return parts.length ? `${parts.join(' ')}` : '';
  }

  get valueAsArray() {
    if (this.value == null) return [];
    return Array.isArray(this.value) ? this.value : [this.value];
  }

  get validationValue() {
    return this.valueAsArray.map(({ resourceId }) => resourceId);
  }

  get isUploading() {
    return this.activeUploads.length > 0;
  }

  get instructionsText() {
    return `${this.acceptedExtensionsText} ${this.fileLimitsText}`;
  }

  @Emit('input')
  emitInput(completedUploads: InputFileValue[]) {
    return this.multiple ? completedUploads : completedUploads[0] || null;
  }

  @Watch('isUploading')
  onUploadingChanged(isUploading: boolean) {
    if (isUploading) this.$wait.start(`upload-${this.spinnerId}`);
    else this.$wait.end(`upload-${this.spinnerId}`);
  }

  @Watch('fileList')
  evalFieldVisibility(fileList: InputFilePartialValue[]) {
    this.isFieldVisible = this.readonly ? fileList.length === 0 : this.multiple || !fileList.length;
  }

  @Watch('value', { immediate: true })
  async onValueChanged(newValue: any, oldValue: any) {
    if (oldValue === undefined) this.evalFieldVisibility(this.valueAsArray);

    const valueList: InputFileListEntry[] = await Promise.all(
      this.valueAsArray.map(async entry => {
        if (this.isInputFileValue(entry)) return entry;
        const { url, size, type, originalFileName } = await this.$api.getFile(entry.resourceId);
        return { key: uuid(), ...entry, url, type, size, originalFileName };
      }),
    );

    const fileList = this.fileList
      .filter(entry => {
        const isPendingUpload = entry.file && !entry.resourceId && !this.deletedEntries.includes(entry.key);
        return isPendingUpload || valueList.some(({ resourceId }) => resourceId === entry.resourceId);
      })
      .map(entry => {
        const valueIndex = valueList.findIndex(({ key }) => key === entry.key);
        return valueIndex >= 0 ? { ...entry, ...valueList.splice(valueIndex, 1)[0] } : entry;
      });

    this.fileList = uniqWith([...fileList, ...valueList], (entryA, entryB) =>
      isEqual(omit(entryA, 'key'), omit(entryB, 'key')),
    );

    this.deletedEntries = [];
  }

  public isInputFileValue(entry: InputFilePartialValue | InputFileListEntry): entry is InputFileValue {
    const { resourceId, url, originalFileName, type, size, key } = entry;

    return (
      isString(resourceId) &&
      isString(url) &&
      isString(originalFileName) &&
      isString(type) &&
      isString(key) &&
      isNumber(size)
    );
  }

  public createFileListEntry(file: File): InputFileListEntry & { file: File } {
    return { file, originalFileName: file.name, size: file.size, type: file.type, key: uuid() };
  }

  public isValidFile(file: File | null): file is File {
    if (!file) return false;
    const isValidSize = !this.maxSizeBytes || file.size <= this.maxSizeBytes;

    const isAcceptedType =
      !this.acceptedFileTypes.length ||
      this.acceptedFileTypes.some(acceptedType => {
        if (acceptedType.includes('/*')) return file.type.startsWith(acceptedType.slice(0, -1));
        if (acceptedType.startsWith('.')) return file.name.toLowerCase().endsWith(acceptedType.toLowerCase());
        return file.type === acceptedType;
      });

    if (isAcceptedType && isValidSize) return true;
    const invalidFile = !isAcceptedType ? InputFileInvalidMessage.Type : InputFileInvalidMessage.Size;
    this.invalidFiles.push({ ...this.createFileListEntry(file), invalidFile });
    return false;
  }

  public async addUploadsToFileList(files: (File | null)[]) {
    if (this.disabled || this.readonly) return;
    this.invalidFiles = [];

    const processedFiles = await Promise.all(
      files.filter(this.isValidFile).map(file => {
        return !this.fileProcessor ? Promise.resolve(file) : this.fileProcessor(file);
      }),
    );

    const fileEntries = processedFiles.filter(this.isValidFile).map(this.createFileListEntry);

    if (!fileEntries.length) return;
    if (this.multiple) return this.fileList.push(...fileEntries);
    this.fileList = fileEntries.slice(0, 1);
    this.emitInput([]);
  }

  public async onInputDrop(event: DragEvent) {
    event.preventDefault();
    this.isDropping = false;
    const files = event.dataTransfer?.items
      ? Array.from(event.dataTransfer.items).map(item => item.getAsFile())
      : Array.from(event.dataTransfer?.files || []);

    await this.addUploadsToFileList(files);
  }

  public async onInputChange({ target }: FileInputEvent) {
    await this.addUploadsToFileList(Array.from(target.files));
  }

  public onFileUploaded({ resourceId, path, key }: UploadFileResult & { key: string }) {
    if (this.deletedEntries.includes(key)) return;
    const fileList = this.fileList
      .map(entry => (entry.key === key ? { ...entry, resourceId, url: path } : entry))
      .filter(this.isInputFileValue);

    this.emitInput(fileList);
  }

  public onDeleteEntry({ key, resourceId, invalidFile }: InputFileListEntry) {
    if (invalidFile) {
      this.invalidFiles = this.invalidFiles.filter(entry => entry.key !== key);
      return;
    }

    this.deletedEntries.push(key);
    const fileList = this.fileList.filter(entry => entry.key !== key);
    if (!resourceId) this.fileList = fileList;
    else this.emitInput(fileList.filter(this.isInputFileValue));
  }

  public onOrderChange() {
    const fileList = this.fileList.filter(this.isInputFileValue);
    this.emitInput(fileList);
  }

  public onUploadEnd(key: string) {
    this.activeUploads = this.activeUploads.filter(uploadKey => uploadKey !== key);
  }

  public onUploadFailed(key: string, serverErrorCode: string | null) {
    const entry = this.fileList.find(entry => entry.key === key);
    if (!entry) return;
    this.fileList = this.fileList.filter(entry => entry.key !== key);
    this.invalidFiles.push({ ...entry, invalidFile: InputFileInvalidMessage.Server, serverErrorCode });
  }
}
